import classnames from "classnames";
import { md, Node } from "djedi-react";
import NextLink from "next/link";
import PropTypes from "prop-types";
import React from "react";
import Collapse from "react-smooth-collapse";

import Appstore from "#components/Appstore";
import Clickable from "#components/Clickable";
import Container from "#components/Container";
import GooglePlay from "#components/GooglePlay";
import WithWindowSize from "#components/WithWindowSize";
import ChevronIcon from "#icons/chevron.svg";
import FacebookIcon from "#icons/facebook.svg";
import InstagramIcon from "#icons/instagram.svg";
import VRLogo from "#icons/vr.svg";
import YouTubeIcon from "#icons/youtube.svg";
import { parseDjediLinks } from "#utils";

import styles from "./Footer.module.css";

const YEAR = new Date().getFullYear();

const INSTRUCTIONS = (
  <Node uri="footer/1/instructions" render={() => null}>{md`
    This node isn’t shown anywhere. It just tells you how to edit the footer links.
    One link per line.
    Format: LINK TEXT | URL
    Lines starting with a pipe (|) are ignored.
    Examples:
    Support | /sv/kundservice
    MTR Nordic | http://www.mtrnordic.se/
    | Ignored | /sv/kampanj
  `}</Node>
);

export default function Footer() {
  return (
    <footer className={styles.root}>
      <div className={styles.upper}>
        <Container>
          <div className={styles.grid}>
            <LinksList
              heading={<Node uri="footer/column-1/heading">Resa med oss</Node>}
              links={
                <Node uri="footer/column-1/links">{md`
                  Res med oss | /sv/res-med-oss
                  Resevillkor och integritetspolicy | /sv/villkor
                  Frågor om bokad biljett | /sv/kundservice/solutions/articles/44000454273-fragor-om-bokad-biljett
                  | Boka gruppresa | /sv/boka-gruppresa
                  Boka sviten | /sv/kundservice/solutions/articles/44000394302-hur-bokar-man-sviten-
                  Tillgänglighet | /sv/kundservice/solutions/articles/44000394380-tillgänglighet
                  Tidtabeller | /sv/kundservice/solutions/articles/44000394688-tidtabeller
                  Samarbete Västtrafik | /sv/kundservice/solutions/articles/44000395357-samarbete-vÄsttrafik
                `}</Node>
              }
            />
            <LinksList
              heading={<Node uri="footer/column-2/heading">Om VR</Node>}
              links={
                <Node uri="footer/column-2/links">{md`
                  Om resan | /sv/kundservice/solutions/folders/44000283993
                  Mat på tåget | /sv/cafe-meny
                  Vad får jag ta med ombord? | /sv/kundservice/solutions/articles/44000394612-vad-far-jag-ta-med-mig-pa-taget-
                  Service ombord | /sv/kundservice/solutions/articles/44000394671-service-ombord
                `}</Node>
              }
            />
            <LinksList
              heading={<Node uri="footer/column-3/heading">Kontakt</Node>}
              links={
                <Node uri="footer/column-3/links">{md`
                  Jobba med oss | /sv/jobba-med-oss
                  Jobba hos MTR Nordic | http://www.mtrnordic.se/jobba-med-oss/
                  Om MTR-koncernen | http://www.mtrnordic.se/om-oss/om-mtr-nordic/
                `}</Node>
              }
            />
            <LinksList
              heading={<Node uri="footer/column-5/heading">Följ oss</Node>}
              links={
                <Node uri="footer/column-4/links">{md`
                  Kundservice | /sv/kundservice
                  Press och media | /sv/press-och-media
                `}</Node>
              }
              extraItems={[<SocialMedia key="social" />]}
            />

            <LinksList
              heading={<Node uri="footer/column-4/heading">Våra Appar</Node>}
              links={
                <Node uri="footer/column-5/links">{md`
                  Kundservice | /sv/kundservice
                  Press och media | /sv/press-och-media
                `}</Node>
              }
              extraItems={[
                <Appstore key="AppStore" />,
                <GooglePlay key="GooglePlay" />,
              ]}
            />
          </div>
        </Container>
      </div>

      <div className={styles.lower}>
        <Container>
          <div className={styles.lowerInner}>
            <VRLogo className={styles.logo} />
            <span className={styles.copyright}>
              <Node uri="footer/copyright" year={YEAR}>
                © MTRX [year]
              </Node>
            </span>
          </div>
        </Container>
      </div>

      {INSTRUCTIONS}
    </footer>
  );
}

LinksList.propTypes = {
  heading: PropTypes.element.isRequired,
  links: PropTypes.element.isRequired,
  extraItems: PropTypes.arrayOf(PropTypes.node.isRequired),
};

LinksList.defaultProps = {
  extraItems: [],
};

function LinksList({ heading, links, extraItems }) {
  const [open, setOpen] = React.useState(false);

  return React.cloneElement(links, {
    edit: false,
    render: function render(state) {
      const items =
        state.type === "success" ? parseDjediLinks(state.content) : [];

      // This seems to be a bug in eslint-plugin-react:
      // eslint-disable-next-line react/prop-types
      if (items.length === 0 && extraItems.length === 0) {
        return null;
      }

      return (
        <div>
          <button
            type="button"
            className={styles.heading}
            onClick={() => setOpen(!open)}
          >
            {heading}
            <ChevronIcon
              className={classnames(styles.mobileOnly, {
                [styles.flipped]: open,
              })}
            />
          </button>
          <WithWindowSize>
            {({ xsDown }) => (
              <Collapse expanded={open || !xsDown}>
                <ul className={styles.list}>
                  {items.map((item, index) => {
                    return (
                      <li key={index}>
                        {typeof item.url === "string" ? (
                          <Clickable href={item.url} className={styles.link}>
                            {item.text}
                          </Clickable>
                        ) : (
                          <NextLink {...item.url}>
                            <a className={styles.link}>{item.text}</a>
                          </NextLink>
                        )}
                      </li>
                    );
                  })}

                  {/* This seems to be a bug in eslint-plugin-react: */}
                  {/* eslint-disable-next-line react/prop-types */}
                  {extraItems.map((link, index) => (
                    <li key={index}>{link}</li>
                  ))}
                </ul>
              </Collapse>
            )}
          </WithWindowSize>
        </div>
      );
    },
  });
}

const SOCIAL_MEDIA = [
  {
    title: "YouTube",
    icon: YouTubeIcon,
    url: "https://www.youtube.com/c/MTRXOfficial",
  },
  {
    title: "Facebook",
    icon: FacebookIcon,
    url: "https://www.facebook.com/wearemtrx",
  },
  {
    title: "Instagram",
    icon: InstagramIcon,
    url: "https://www.instagram.com/wearemtrx",
  },
];

function SocialMedia() {
  return (
    <div className={styles.socialMedia}>
      {SOCIAL_MEDIA.map(({ title, icon: Icon, url }) => (
        <Clickable
          key={title}
          href={url}
          title={title}
          className={classnames(styles.link, styles.iconLink)}
        >
          <Icon />
        </Clickable>
      ))}
    </div>
  );
}
